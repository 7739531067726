import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../Views/Footer";
import Header from "../Views/Header";
import axios from "axios";
import Styles from "./Home.module.css";

const Home = () => {
  const [token, setToken] = useState("");
  const [users, setUsers] = useState([]);
  const [checkin, setCheckIn] = useState([]);
  const [checkout, setCheckout] = useState([]);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_Url}/attendance`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log(res.data);
          setCheckIn(res.data.attendance);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(`${process.env.REACT_APP_Base_Url}/checkout`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log(res.data);
          setCheckout(res.data.checkout_time);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(`${process.env.REACT_APP_Base_Url}/get_users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log(res.data);
          setUsers(res.data.users);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  // const handleSideClick = (e) => {
  //   const sideBar = document.getElementById("sidebar");
  //   sideBar.classList.toggle("fade-in");
  // };

  return (
    <>
      <Header />
      {/* <header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-3">
              <div id="logo_home">
                <h1>
                  <a href="/dashboard">Attendance Checker</a>
                </h1>
              </div>
            </div>
            <div className="col-9">
              <nav>
                <ul className="cd-primary-nav" id="sidebar">
                  <li>
                    <a href="/" className="animated_link">
                      Login
                    </a>
                  </li>
                  <li>
                    <a href="/clock_in" className="animated_link">
                      Clock In / Clock Out
                    </a>
                  </li>
                  <li>
                    <a href="/dashboard" className="animated_link">
                      Home / Dashboard
                    </a>
                  </li>
                  <li>
                    <a href="/absent_message" className="animated_link">
                      Leave a message
                    </a>
                  </li>
                  <li>
                    <a href="/profile" className="animated_link">
                      Change Password
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      onClick={() => LogOut()}
                      className="animated_link">
                      Log out
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        container
      </header> */}

      {/* create a grid of users with 4 items in a row */}
      <div className="container">
        <div className="row">
          {users.map((user) => (
            <div className="col-3 p-2" key={user?._id}>
              <div className="card">
                <img
                  src={user?.profile_image}
                  id="image-container"
                  className="card-img-top"
                  alt="..."
                />

                <div className="role">
                  <span
                    className="position-absolute top-0 start-0 translate-middle badge bg-warning"
                    id={Styles.roles}>
                    {user?.role}
                  </span>
                </div>

                <div className="card-body">
                  <h5 className="card-title h4 fw-bold">
                    <span className="h4 pr-1">Name :</span>
                    {user?.gender === "male" ? (
                      <span className=" text-primary pr-1">Mr.</span>
                    ) : (
                      <span className=" text-danger pr-1"> Mrs.</span>
                    )}
                    {user?.last_name} {""}
                    {user?.first_name}
                  </h5>
                  <p className="card-text h4 fw-bold">
                    Department : {user?.title}
                  </p>
                  <hr />

                  {checkin.map((inna) => {
                    return (
                      <>
                        {inna.user === user?._id && (
                          <p className="fw-bold" key={inna?._id}>
                            <span className="text-success">Arrival:</span>
                            <br />
                            {inna?.date}, {""}
                            {inna?.time}
                          </p>
                        )}
                      </>
                    );
                  })}

                  {checkout.map((out) => {
                    return (
                      <>
                        {out.user === user?._id && (
                          <p className="fw-bold" key={out?._id}>
                            <span className="text-danger">Departure:</span>
                            <br />
                            {out?.date}, {""}
                            {out?.time}
                          </p>
                        )}
                      </>
                    );
                  })}
                </div>

                <div className="card-footer text-center d-grid">
                  <button className="btn  btn-info text-white">
                    <Link
                      to={`/${user.first_name.toLowerCase()}/records`}
                      state={{ user: user?._id }}
                      style={{ color: "white" }}>
                      View all Checkin / Checkout
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />

      <div className="cd-overlay-nav">
        <span />
      </div>
      <div className="cd-overlay-content">
        <span />
      </div>
      {/* <a href="#0" className="cd-nav-trigger">
        Menu
        <span className="cd-icon" onClick={handleSideClick} />
      </a> */}
    </>
  );
};

export default Home;
