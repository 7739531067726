import axios from "axios";
import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import Styles from "./Activation.module.css";

const Activation = () => {
  const [messages, setMessages] = useState("");
  const location = useLocation();
  // console.log(location);

  const parse = queryString.parse(location.search);
  // console.log(parse.email);
  // console.log(parse.token);

  useEffect(() => {
    if (parse) {
      axios
        .post(`${process.env.REACT_APP_Base_Url}/activate_user`, {
          email: parse.email,
          token: parse.token,
        })
        .then((res) => {
          // console.log(res.data);

          if (res.data.status === 200) {
            setMessages(res.data.message);
            // alert(res.data.message);
            // window.location.replace("/");
          } else {
            setMessages(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [parse]);

  return (
    <div className={Styles.main}>
      <img src="img/checker.jpg" alt="" className={Styles.img_fluid} />
      {messages && <h1 className={Styles.mega}>{messages}</h1>}

      {messages && (
        <a href="/" className={Styles.mega}>
          Click here to <em style={{ color: "red" }}>login</em>
        </a>
      )}
    </div>
  );
};

export default Activation;
