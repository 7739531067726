import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../Views/Footer";
import Header from "../Views/Header";
import HomeStyle from "./ClockIn.module.css";

const ClockIn = () => {
  const [token, setToken] = useState("");
  const [users, setUsers] = useState({
    userDetails: null,
  });

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_Url}/user_details`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log(res.data);
          setUsers((users) => {
            return {
              ...users,
              userDetails: res.data.user_details,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const onCheckout = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_Url}/clock_out`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.newCheckout) {
          alert(res.data.message);
          window.location.replace("/dashboard");
        } else {
          alert("You have already checked in");
          window.location.replace("/dashboard");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCheckin = () => {
    axios
      .post(
        `${process.env.REACT_APP_Base_Url}/clock_in`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.newAttendance) {
          alert(res.data.message);
          window.location.replace("/dashboard");
        } else {
          alert("You have already checked in");
          window.location.replace("/dashboard");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header />

      <div className={HomeStyle.main}>
        {users.userDetails && (
          <div className={HomeStyle.content}>
            <div className="card" style={{ width: "500px" }}>
              <img
                src={users.userDetails?.profile_image}
                className="card-img-top"
                width="100%"
                height="100%"
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title h4 fw-bold">
                  Name :
                  <span className="text-danger mx-1">
                    {users.userDetails?.gender === "male" ? "Mr." : "Mrs."}{" "}
                  </span>
                  {users.userDetails?.last_name} {users.userDetails?.first_name}
                </h5>
                <p className="card-text h4 fw-bold">
                  Department : {users.userDetails?.title}
                </p>

                <div className={HomeStyle.trigers}>
                  <button
                    className="btn btn-primary"
                    onClick={() => onCheckin()}>
                    Clock In
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => onCheckout()}>
                    Clock Out
                  </button>
                </div>
              </div>
              <div className="card-footer text-muted p-2 m-2">
                <p className="text-danger"> Can't make it to office today ?</p>
                <a href="/absent_message">leave a message here ...</a>
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer />

      <div className="cd-overlay-nav">
        <span />
      </div>
      {/* cd-overlay-nav */}
      <div className="cd-overlay-content">
        <span />
      </div>
      {/* cd-overlay-content */}
    </>
  );
};

export default ClockIn;
