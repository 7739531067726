import React, { useState, useEffect } from "react";
import axios from "axios";

const Header = () => {
  const [token, setToken] = useState("");

  const LogOut = () => {
    // clear token and history of the person who logged out
    sessionStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (tk) {
      axios
        .get(`${process.env.REACT_APP_Base_Url}/attendance`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data.status === 401 && res.data.message === "jwt expired") {
            sessionStorage.clear();
            window.location.href = "/";
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, [token]);

  const handleSideClick = (e) => {
    const sideBar = document.getElementById("sidebar");
    sideBar.classList.toggle("fade-in");
  };

  return (
    <>
      <header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-3">
              <div id="logo_home">
                <h1>
                  <a href="/dashboard">Attendance Checker</a>
                </h1>
              </div>
            </div>
            <div className="col-9">
              <nav>
                <ul className="cd-primary-nav" id="sidebar">
                  <li>
                    <a href="/" className="animated_link">
                      Login
                    </a>
                  </li>
                  <li>
                    <a href="/clock_in" className="animated_link">
                      Clock In / Clock Out
                    </a>
                  </li>
                  <li>
                    <a href="/dashboard" className="animated_link">
                      Home / Dashboard
                    </a>
                  </li>
                  <li>
                    <a href="/message/absent" className="animated_link">
                      Leave a message
                    </a>
                  </li>
                  <li>
                    <a href="/profile" className="animated_link">
                      Change Password
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      onClick={() => LogOut()}
                      className="animated_link">
                      Log out
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        {/* container */}
      </header>

      {/* mobile screen navigator handler */}
      <div className="cd-nav-trigger" onClick={handleSideClick}>
        Menu
        <span className="cd-icon" />
      </div>
    </>
  );
};

export default Header;
