import React from "react";
import { Outlet } from "react-router-dom";

const Device = () => {
  // console log the type of device used

  // const data = window.navigator;
  // const data = window.navigator.userAgentData;
  const os = window.navigator.platform;

  console.log(os);

  // are 'aix', 'darwin', 'freebsd','linux','openbsd', 'sunos', and 'win32'.
  // 'Linux' on Linux, 'Darwin' on macOS, and 'Win32' on Windows.

  return os === "Win32" || os === "Linux x86_64" || os === "MacIntel" ? (
    <Outlet />
  ) : (
    <h1 className="text-center text-danger fw-bold">
      {os} Device is Not Supported
    </h1>
  );
};

export default Device;
