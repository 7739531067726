import React, { useEffect, useState } from "react";
import Footer from "../Views/Footer";
import Header from "../Views/Header";
import axios from "axios";
import RecordStyles from "./UserRecords.module.css";
import { Link, useLocation } from "react-router-dom";

const UserRecords = () => {
  const location = useLocation();
  console.log(location);
  const [id, setId] = useState("");
  const [token, setToken] = useState(null);
  const [checkin, setCheckIn] = useState([]);
  const [absent, setAbsent] = useState([]);
  const [display_month, setDisplayMonth] = useState(null);
  const [display_total, setDisplayTotal] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    if (location.state) {
      setId(location.state.user);
    }
  }, [location.state]);

  useEffect(() => {
    if (id) {
      axios
        .post(
          `${process.env.REACT_APP_Base_Url}/check_in_list`,
          {
            user: id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          setCheckIn(res.data.attendance);
          setDisplayMonth(res.data.month);
          setDisplayTotal(res.data.totalDays);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .post(
          `${process.env.REACT_APP_Base_Url}/check_out_list`,
          {
            user: id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .post(
          `${process.env.REACT_APP_Base_Url}/get_absent_message`,
          {
            user: id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          setAbsent(res.data.absent_message);
        });
    }
  }, [token, id]);

  return (
    <>
      <Header />

      <main className="container" style={{ padding: "5px 0px 200px 0px" }}>
        {checkin && (
          <h2
            className="text-uppercase"
            style={{ borderBottom: "2px solid #000" }}>
            {" "}
            Time of Arrival for the month of {display_month}
          </h2>
        )}
        {/* a card with 6 items in a row */}
        <div className="row">
          {checkin.map((item) => {
            return (
              <div className="col-md-2 p-2" key={item?._id}>
                <div className="card">
                  {/* check if item?.time is beyond 8:10 change background color to red  */}
                  {item?.time > "5:00:00 AM" && item?.time < "8:11:00 AM" ? (
                    // if item?.time is less than 8:10 change background color to green
                    <div className="card-body fw-bolder">
                      <h5 className="card-title fw-bolder text-uppercase">
                        <span className="badge bg-secondary p-2">
                          {" "}
                          {item?.day.slice(0, 3)}
                        </span>
                      </h5>
                      <h5 className="card-title fw-bolder">
                        Date : {item?.date}
                      </h5>

                      <p className="card-text fw-bolder">Time : {item?.time}</p>
                    </div>
                  ) : (
                    <div className="card-body bg-danger text-white">
                      <h5 className="card-title text-white fw-bolder text-uppercase">
                        <span className="badge bg-white p-2 text-danger fw-bolder">
                          {" "}
                          {item?.day.slice(0, 3)}
                        </span>
                      </h5>
                      <h5 className="card-title text-white fw-bolder">
                        Date : {item?.date}
                      </h5>
                      {item?.present === false ? (
                        <p className="card-text">
                          <span className="text-white fw-bolder">Absent</span>
                        </p>
                      ) : (
                        <p className="card-text">
                          <span className="text-white fw-bolder">
                            Time : {item?.time}
                          </span>
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <br />
              </div>
            );
          })}
        </div>
      </main>

      <main className="container pt-2 pb-5">
        {checkin && (
          <h2
            className="text-uppercase"
            style={{ borderBottom: "2px solid #000" }}>
            {" "}
            Comment(s) for {display_month}
          </h2>
        )}
        {/* a card with 6 items in a row */}
        <div className="row">
          {absent.map((item) => {
            return (
              <div className="col-md-3 p-2" key={item?._id}>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title fw-bolder">
                      Date : {item?.date}
                    </h5>
                    <h6 className="card-text">Reason : {item?.reason}</h6>
                    <p className="card-text fw-bolder">
                      {" "}
                      Comment : {item?.comment}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <>
          {/* <h2 className="text-uppercase">Summary</h2> */}
          <div className="card my-5" id={RecordStyles.summary}>
            <div className="card-footer fw-bolder" style={{ color: "#08cf1c" }}>
              Total Present : {checkin?.length}
            </div>
            <div className="card-footer fw-bolder" style={{ color: "red" }}>
              Total Absent/Late : {absent?.length}
            </div>
            <div className="card-footer fw-bolder" style={{ color: "#000" }}>
              <Link to="/export" state={{ user: id }}>
                <i className="pi pi-download"></i> Download Absent/Late Report
              </Link>
            </div>
            <div
              className="card-footer fw-bolder"
              style={{ marginLeft: "auto" }}>
              {" "}
              Total days in {display_month} : {display_total}{" "}
            </div>
          </div>
        </>
      </main>

      <Footer />

      <div className="cd-overlay-nav">
        <span />
      </div>
      <div className="cd-overlay-content">
        <span />
      </div>
    </>
  );
};

export default UserRecords;
