import React from "react";

const Footer = () => {
  return (
    <>
      <footer id="home" className="clearfix">
        <p>© 2022 Roscareer</p>
        <ul>
          {/* <li>
            <a href="#/" className="animated_link" target="_parent">
              Purchase this template
            </a>
          </li> */}
          <li>
            <a href="#0" className="animated_link">
              Terms and conditions
            </a>
          </li>
          <li>
            <a href="#0" className="animated_link">
              Contacts
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
};

export default Footer;
