import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import AbsentMessage from "./Components/Absent/AbsentMessage";
import ClockIn from "./Components/ClockIn/ClockIn";
import Home from "./Components/Dashboard/Home";
import Export from "./Components/Export/Export";
import Login from "./Components/Login/Login";
import Profile from "./Components/Profile/Profile";
import DownloadData from "./Components/UserRecords/DownloadData";
import UserRecords from "./Components/UserRecords/UserRecords";
import ProtectedRoute from "./ProtectedRoute";
import Activation from "./Components/Activation/Activation";
import Register from "./Components/Register/Register";
import Forgot from "./Components/Forgot Password/Forgot";
import Reset from "./Components/Reset Password/Reset";
import Device from "./Device";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Device />}>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/activation" element={<Activation />} />
          <Route path="/reset_password" element={<Reset />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/clock_in" element={<ClockIn />} />
            <Route path="/dashboard" element={<Home />} />
            <Route path="/:user/records" element={<UserRecords />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/message/absent" element={<AbsentMessage />} />
            <Route path="/download_data" element={<DownloadData />} />
            <Route path="/export" element={<Export />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
