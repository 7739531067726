import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../Views/Footer";
import Header from "../Views/Header";
import HomeStyle from "../ClockIn/ClockIn.module.css";

const AbsentMessage = () => {
  const [token, setToken] = useState("");
  const [comment, setComment] = useState("");
  const [reason, setReason] = useState("");
  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState({
    userDetails: null,
  });

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (token) {
      axios
        .get(`${process.env.REACT_APP_Base_Url}/get_users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log(res.data);
          setUsers(res.data.users);
        })
        .catch((err) => {
          console.log(err);
        });

      // get current user details
      axios
        .get(`${process.env.REACT_APP_Base_Url}/user_details`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log(res.data);
          setCurrentUser((currentUser) => {
            return {
              ...currentUser,
              userDetails: res.data.user_details,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(comment);
    console.log(reason);

    axios
      .post(
        `${process.env.REACT_APP_Base_Url}/create_absent_message`,
        {
          comment,
          reason,
          role: currentUser.userDetails.role,
          user_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data.status === 200) {
          alert(res.data.message);
          window.location.replace("/dashboard");
        } else {
          alert(res.data.message);
          window.location.reload(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header />

      <div className={HomeStyle.main}>
        {currentUser.userDetails && (
          <div className={HomeStyle.content}>
            <div className="card" style={{ width: "610px" }}>
              <img
                src={currentUser.userDetails?.profile_image}
                className="card-img-top"
                width="100%"
                height="100%"
                alt="..."
              />

              <div className="card-body">
                <h4 className="card-text fw-bold">
                  <span className="card-title h3 mx-2">NB:</span>
                  <span className="text-info px-2">
                    {currentUser.userDetails?.gender === "male" ? (
                      <span className="text-danger pr-2">Mr</span>
                    ) : (
                      <span className="text-danger pr-2">Mrs / Ms</span>
                    )}
                    {currentUser.userDetails?.last_name}{" "}
                    {currentUser.userDetails?.first_name}
                  </span>{" "}
                  please state your reason
                </h4>
              </div>

              <form
                onSubmit={handleSubmit}
                className="card-footer text-muted m-2">
                <div className="">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    required
                    onChange={(e) => setUserId(e.target.value)}>
                    <option defaultValue={""} value={""}>
                      ------- select a user -------
                    </option>
                    {users.map((user) => (
                      <option key={user?._id} value={user?._id}>
                        {user?.last_name} {user?.first_name}
                      </option>
                    ))}
                  </select>
                </div>

                <br />
                <div className="">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    required
                    onChange={(e) => setReason(e.target.value)}>
                    <option defaultValue={""} value={""}>
                      ------- select a reason -------
                    </option>
                    <option value={"Absent"}>Absent</option>
                    <option value={"Late"}>Late</option>
                  </select>
                </div>
                <br />

                <textarea
                  style={{ width: "100%", height: "100px" }}
                  placeholder="Leave a comment ..."
                  className="mb-3"
                  onChange={(e) => setComment(e.target.value)}></textarea>

                <br />

                <div className="d-grid">
                  <button className="btn btn-primary" type="Submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>

      <Footer />

      <div className="cd-overlay-nav">
        <span />
      </div>
      {/* cd-overlay-nav */}
      <div className="cd-overlay-content">
        <span />
      </div>
      {/* cd-overlay-content */}
    </>
  );
};

export default AbsentMessage;
