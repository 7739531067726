import React, { useEffect, useState } from "react";
import Footer from "../Views/Footer";
import Header from "../Views/Header";
import axios from "axios";
// import Styles from "./Register.module.css";

const Register = () => {
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [gender, setGender] = useState("");
  const [profile_image, setProfileImage] = useState("");
  const [token, setToken] = useState("");
  let formData = new FormData();

  useEffect(() => {
    setToken(sessionStorage.getItem("token"));
  }, []);

  const onFileChange = (e) => {
    console.log(e.target.files);
    if (e.target && e.target.files[0]) {
      setProfileImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirm_password) {
      alert("Password and Confirm Password must be same");
      return;
    }

    formData.append("title", title);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("address", address);
    formData.append("phone", phone);
    formData.append("role", role);
    formData.append("gender", gender);
    formData.append("profile_image", profile_image);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    // console.log(formData);
    await axios
      .post(`${process.env.REACT_APP_Base_Url}/register`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res);
        alert(res.data.message);
        if (res.data.status === 201) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err);
        // alert(err.response.data.message);
      });
  };

  return (
    <>
      <Header />

      <main>
        <div id="form_container">
          <div className="row">
            <div className="col-lg-8">
              <div id="wizard_container">
                {/* /top-wizard */}
                <form onSubmit={handleSubmit}>
                  <div id="middle-wizard">
                    <div className="step">
                      <h3 className="main_question">
                        <strong>1/3</strong> Please fill with your details
                      </h3>

                      {/* /row */}
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="first_name"
                              className="form-control"
                              placeholder=" First Name"
                              required
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="last_name"
                              className="form-control"
                              placeholder=" Last Name"
                              required
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              name="title"
                              className="form-control"
                              placeholder=" Department"
                              required
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder=" Email"
                              required
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="address"
                              className="form-control"
                              placeholder="Address"
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="tel"
                              name="phone"
                              className="form-control"
                              placeholder=" Contact Number"
                              maxLength={10}
                              minLength={10}
                              required
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row py-3">
                        <div className="col-md-6">
                          <div className="form-group">
                            <select
                              name="role"
                              className="form-select"
                              required
                              onChange={(e) => setRole(e.target.value)}>
                              <option value=""> Select Role</option>
                              <option value="staff">Staff</option>
                              <option value="admin"> Admin</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <select
                              name="role"
                              className="form-select"
                              required
                              onChange={(e) => setGender(e.target.value)}>
                              <option value=""> Select Gender</option>
                              <option value="female">Female</option>
                              <option value="male"> Male</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="file"
                              name="profile_image"
                              accept="image/*"
                              required
                              onChange={onFileChange}
                            />
                          </div>
                        </div>
                      </div>

                      {/* /row */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              placeholder="Password"
                              required
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              placeholder="Confirm Password"
                              required
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {/* /row */}
                    </div>
                  </div>
                  {/* /middle-wizard */}
                  <div className="btn ">
                    <button
                      type="submit"
                      className="btn  btn-primary"
                      style={{ padding: "5px 31px" }}>
                      Register
                    </button>
                  </div>

                  {/* /bottom-wizard */}
                </form>
              </div>
              {/* /Wizard container */}
            </div>

            <div className="col-lg-4">
              <div id="left_form_register">
                <figure>
                  <img src="img/registration_bg.svg" alt="" />
                </figure>
                <h2>Register</h2>
                <a
                  href="#0"
                  id="more_info"
                  data-bs-toggle="modal"
                  data-bs-target="#more-info">
                  <i className="pe-7s-info" />
                </a>
              </div>
            </div>

            <br />
            <div className="p-3">
              <span className="mx-2"> have an account already?</span>
              <a href="/">login</a>
            </div>
          </div>
          {/* /Row */}
        </div>
        {/* /Form_container */}
      </main>

      <Footer />

      <div>
        <div className="cd-overlay-nav">
          <span />
        </div>
        {/* cd-overlay-nav */}
        <div className="cd-overlay-content">
          <span />
        </div>
        {/* cd-overlay-content */}
        <a href="#0" className="cd-nav-trigger">
          Menu
          <span className="cd-icon" />
        </a>
        {/* Modal terms */}
      </div>
    </>
  );
};

export default Register;
