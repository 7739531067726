import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useLocation } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import axios from "axios";
import Header from "../Views/Header";
import Footer from "../Views/Footer";
const Export = () => {
  const [products, setProducts] = useState([]);
  // const [selectedProducts, setSelectedProducts] = useState([]);
  const [token, setToken] = useState(null);
  const [id, setId] = useState("");
  const dt = useRef(null);
  const location = useLocation();
  // console.log(location);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    if (location.state) {
      setId(location.state.user);
      // console.log(location.state.user);
    }
  }, [location.state]);

  useEffect(() => {
    if (id) {
      axios
        .post(
          `${process.env.REACT_APP_Base_Url}/check_in_list`,
          {
            user: id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          setProducts(res.data.attendance);
          // setCheckIn(res.data.attendance);
          // setDisplayMonth(res.data.month);
          // setDisplayTotal(res.data.totalDays);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, token]);

  const cols = [
    { field: "user_name", header: "user_name" },
    { field: "date", header: "date" },
    { field: "day", header: "day" },
    { field: "time", header: "time" },
  ];

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(products);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "products");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const header = (
    <div className="flex align-items-center export-buttons">
      <Button
        type="button"
        icon="pi pi-file-excel"
        onClick={exportExcel}
        className="p-button-success mr-2"
        data-pr-tooltip="XLS"
      />
    </div>
  );

  return (
    <>
      <Header />
      <div className="card p-5 m-5">
        {/* <h5>Export</h5> */}

        <Tooltip target=".export-buttons>button" position="bottom" />

        <DataTable
          ref={dt}
          value={products}
          header={header}
          dataKey="id"
          responsiveLayout="scroll"
          selectionMode="multiple">
          {cols.map((col, index) => (
            <Column key={index} field={col.field} header={col.header} />
          ))}
        </DataTable>
      </div>
      <Footer />
      <div className="cd-overlay-nav">
        <span />
      </div>
      {/* cd-overlay-nav */}
      <div className="cd-overlay-content">
        <span />
      </div>
      {/* cd-overlay-content */}
      <a href="#0" className="cd-nav-trigger">
        Menu
        <span className="cd-icon" />
      </a>
    </>
  );
};

export default Export;
