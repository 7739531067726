import React, { useState, useEffect } from "react";
import Footer from "../Views/Footer";
import Header from "../Views/Header";
import axios from "axios";

const Profile = () => {
  const [old_password, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_Base_Url}/update_password`,
        {
          old_password,
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        alert(res.data.message);
        if (res.data.status === 200) {
          window.location.href = "/dashboard";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header />

      <main>
        <div id="form_container">
          <div className="row">
            <div className="col-lg-5">
              <div id="left_form">
                <figure>
                  <img src="img/registration_bg.svg" alt="" />
                </figure>
                <h2>Change your Password</h2>
                <a
                  href="#0"
                  id="more_info"
                  data-bs-toggle="modal"
                  data-bs-target="#more-info">
                  <i className="pe-7s-info" />
                </a>
              </div>
            </div>
            <div className="col-lg-7">
              <div id="wizard_container">
                {/* /top-wizard */}
                <form onSubmit={handleSubmit}>
                  <div id="middle-wizard">
                    <div className="step">
                      <h3 className="main_question">
                        <strong>2/3</strong> Change your password
                      </h3>

                      {/* /row */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="password"
                              name="old password"
                              className="form-control"
                              placeholder="Your Old Password"
                              onChange={(e) => setOldPassword(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* /row */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              placeholder="Password"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* /row */}
                    </div>
                  </div>
                  {/* /middle-wizard */}
                  <div className="btn ">
                    <button
                      type="submit"
                      className="btn  btn-primary"
                      style={{ padding: "5px 31px" }}>
                      Submit
                    </button>
                  </div>
                  <br />
                  <div className="p-3">
                    <span className="mr-2"> forgot your password?</span>
                    <a href="/forgot">Forgot Password</a>
                  </div>
                  {/* /bottom-wizard */}
                </form>
              </div>
              {/* /Wizard container */}
            </div>
          </div>
          {/* /Row */}
        </div>
        {/* /Form_container */}
      </main>

      <Footer />

      <div>
        <div className="cd-overlay-nav">
          <span />
        </div>
        {/* cd-overlay-nav */}
        <div className="cd-overlay-content">
          <span />
        </div>
        {/* cd-overlay-content */}
      </div>
    </>
  );
};

export default Profile;
