import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// <!-- BASE CSS -->
import "./assets/css/bootstrap.min.css";
import "./assets/css/bootstrap.min.css.map";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/menu.css";
import "./assets/css/animate.min.css";
import "./assets/css/icon_fonts/css/all_icons_min.css";
import "./assets/css/skins/square/grey.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
